import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "news-container" }
const _hoisted_2 = { class: "release-date" }
const _hoisted_3 = { class: "release-version" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NewsContent = _resolveComponent("NewsContent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[1] || (_cache[1] = _createElementVNode("h1", null, "News and Updates", -1)),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.updates, (update) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "release",
        key: update
      }, [
        _cache[0] || (_cache[0] = _createElementVNode("div", { class: "divider" }, null, -1)),
        _createElementVNode("h2", null, _toDisplayString(update.title), 1),
        _createElementVNode("p", _hoisted_2, _toDisplayString(update.date), 1),
        _createElementVNode("p", _hoisted_3, _toDisplayString(update.version), 1),
        _createVNode(_component_NewsContent, {
          content: update.content
        }, null, 8, ["content"])
      ]))
    }), 128))
  ]))
}