import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "main-container"
}
const _hoisted_2 = { class: "top-container" }
const _hoisted_3 = { class: "refinement-title" }
const _hoisted_4 = { class: "refinement-actions" }
const _hoisted_5 = { class: "refinement-actions-buttons" }
const _hoisted_6 = {
  key: 0,
  class: "session-container"
}
const _hoisted_7 = { class: "left-container" }
const _hoisted_8 = {
  key: 0,
  class: "right-container"
}
const _hoisted_9 = { class: "estimate-tiles" }
const _hoisted_10 = { class: "tile-container" }
const _hoisted_11 = ["onClick"]
const _hoisted_12 = {
  key: 0,
  class: "current-votes-container"
}
const _hoisted_13 = { class: "voting-row" }
const _hoisted_14 = { class: "vote" }
const _hoisted_15 = { class: "user-vote-info-container" }
const _hoisted_16 = ["src"]
const _hoisted_17 = { style: {"text-align":"center"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GenericButton = _resolveComponent("GenericButton")!
  const _component_UserBar = _resolveComponent("UserBar")!
  const _component_WorkItem = _resolveComponent("WorkItem")!
  const _component_LargeTextContainers = _resolveComponent("LargeTextContainers")!
  const _component_SubmitEstimate = _resolveComponent("SubmitEstimate")!

  return (_ctx.refinement.loaded && !_ctx.appState.loading)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("h3", _hoisted_3, _toDisplayString(_ctx.refinementTitle), 1),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_GenericButton, {
                text: "Refresh",
                iconSrc: _ctx.icons.general.reloadWhite,
                onClick: _ctx.refreshSession,
                disabled: !_ctx.interactable
              }, null, 8, ["iconSrc", "onClick", "disabled"]),
              _createVNode(_component_GenericButton, {
                text: "Leave",
                iconSrc: _ctx.icons.general.leaveWhite,
                onClick: _ctx.leaveSession
              }, null, 8, ["iconSrc", "onClick"])
            ]),
            _createVNode(_component_UserBar)
          ])
        ]),
        (!_ctx.appState.loading)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.workItems, (item) => {
                  return (_openBlock(), _createBlock(_component_WorkItem, {
                    key: item?.id,
                    item: item,
                    onItemSelect: ($event: any) => (_ctx.itemSelect(item?.id)),
                    theme: _ctx.theme,
                    disabled: !_ctx.interactable
                  }, null, 8, ["item", "onItemSelect", "theme", "disabled"]))
                }), 128))
              ]),
              (_ctx.selectedWorkItem >= 0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                    _createVNode(_component_LargeTextContainers, {
                      selectedWorkItem: _ctx.workItems[_ctx.selectedWorkItem],
                      refinementId: _ctx.sessionId,
                      onUpdateSelectedWorkItem: _ctx.updateSelectedWorkItem
                    }, null, 8, ["selectedWorkItem", "refinementId", "onUpdateSelectedWorkItem"]),
                    _createElementVNode("div", _hoisted_9, [
                      _cache[2] || (_cache[2] = _createElementVNode("h3", null, "Votes", -1)),
                      _createElementVNode("div", _hoisted_10, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.votingTile, (tileVal) => {
                          return (_openBlock(), _createElementBlock("button", {
                            type: "button",
                            class: "tile",
                            key: tileVal,
                            onClick: ($event: any) => (_ctx.addToVotingList(tileVal, $event))
                          }, _toDisplayString(tileVal), 9, _hoisted_11))
                        }), 128))
                      ]),
                      (_ctx.votesList.length > 0)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                            _createElementVNode("b", null, _toDisplayString(`Current Votes (${_ctx.votesList.length} / ${Object.keys(_ctx.refinement.session.users).length})`), 1),
                            _createElementVNode("div", _hoisted_13, [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.votesList, (vote) => {
                                return (_openBlock(), _createElementBlock("div", {
                                  class: "vote-container",
                                  key: vote
                                }, [
                                  _createElementVNode("div", _hoisted_14, [
                                    _createElementVNode("div", null, _toDisplayString(_ctx.revealed ? vote.value : '...'), 1)
                                  ]),
                                  _createElementVNode("div", _hoisted_15, [
                                    _createElementVNode("img", {
                                      style: {"border-radius":"50%"},
                                      src: `data:image/png;base64,${vote.userInfo?.avatar}`,
                                      width: "25",
                                      height: "25",
                                      loading: "lazy"
                                    }, null, 8, _hoisted_16),
                                    _createElementVNode("span", _hoisted_17, _toDisplayString(_ctx.formatUserName(vote.userInfo?.user)), 1)
                                  ])
                                ]))
                              }), 128))
                            ]),
                            (!_ctx.revealed)
                              ? (_openBlock(), _createBlock(_component_GenericButton, {
                                  key: 0,
                                  btnClass: "reveal-button",
                                  text: "Reveal",
                                  onClick: _ctx.revealVotes,
                                  disabled: !_ctx.interactable
                                }, null, 8, ["onClick", "disabled"]))
                              : (_openBlock(), _createBlock(_component_SubmitEstimate, {
                                  key: 1,
                                  uniqueVotes: _ctx.uniqueVotes,
                                  onRevote: _cache[0] || (_cache[0] = ($event: any) => (_ctx.itemSelect(_ctx.workItems[_ctx.selectedWorkItem]?.id))),
                                  onSubmitEstimate: _cache[1] || (_cache[1] = (value) => _ctx.submitEstimate(value))
                                }, null, 8, ["uniqueVotes"]))
                          ]))
                        : _createCommentVNode("", true)
                    ])
                  ]))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}