<template>
    <Bar :data="chartData" :options="chartOptions" />
</template>

<script lang="tsx">
import { defineComponent } from 'vue';
import { Bar } from 'vue-chartjs';
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js';
import { randomColor, StateLabelColorMap } from '@/Utils/ColourMapping';
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ChartDataLabels);

export default defineComponent({
    name: 'BarChart',
    components: { Bar },
    props: {
        data: {
            type: Object as () => Record<string, any>,
            default: () => ({})
        },
        labels: {
            type: Array as () => string[],
            default: () => []
        },
        multipleBars: {
            type: Boolean,
            default: () => false
        },
        theme: String,
        title: String
    },
    computed: {
        chartData () {
            if (this.multipleBars) {
                const dataSets = this.data.map((dataset: any) => {
                    return {
                        ...dataset,
                        borderWidth: 0.5
                    };
                });

                return {
                    labels: this.labels,
                    datasets: dataSets,
                };
            } else {
                const labels = Object.keys(this.data);
                const backgroundColors = labels.map(label => StateLabelColorMap[label]?.background || randomColor());

                return {
                    labels: labels,
                    datasets: [
                        {
                            data: Object.values(this.data),
                            backgroundColor: backgroundColors,
                            borderWidth: 0.5
                        },
                    ]
                };
            }
        },
        chartOptions () {
            return {
                responsive: true,
                maintainAspectRatio: true,
                aspectRatio: 1.5,
                plugins: {
                    legend: {
                        display: this.multipleBars,
                        labels: {
                            font: {
                                size: 14
                            },
                            color: this.theme === 'dark' ? 'white' : 'black'
                        }
                    },
                    title: {
                        display: true,
                        text: this.title,
                        font: {
                            size: 20
                        },
                        color: this.theme === 'dark' ? 'white' : 'black'
                    },
                    datalabels: {
                        formatter: (value: number, context: any) => {
                            if (!this.multipleBars) {
                                const dataset = context.chart.data.datasets[0].data;
                                const total = dataset.reduce((sum: number, val: number) => sum + val, 0);
                                const percentage = ((value / total) * 100).toFixed(2);
                                return `${value} (${percentage}%)`;
                            }
                            return value > 0 ? value : null;
                        },
                        color: this.theme === 'dark' ? 'white' : 'black',
                        font: {
                            size: 20,
                            weight: 'bold' as 'bold' | 'normal' | 'bolder' | 'lighter'
                        }
                    }
                },
                scales: {
                    x: {
                        grid: {
                            color: this.theme === 'dark' ? 'rgba(255, 255, 255, 0.2)' : 'rgba(0, 0, 0, 0.1)'
                        },
                        ticks: {
                            color: this.theme === 'dark' ? 'white' : 'black',
                            font: {
                                size: 14
                            }
                        },
                    },
                    y: {
                        grid: {
                            color: this.theme === 'dark' ? 'rgba(255, 255, 255, 0.2)' : 'rgba(0, 0, 0, 0.1)'
                        },
                        ticks: {
                            color: this.theme === 'dark' ? 'white' : 'black',
                            font: {
                                size: 14
                            }
                        }
                    }
                },
            };
        }
    }
});
</script>