import router from '../../Router/router';
import { user } from '@/Data/Store/user';
import { resetAppStore } from '../Store/appState';
import { resetUserStore } from '../Store/user';
import { fullReset, microsoftSignOutReset } from '../../Utils/HelperFunctions';
import { routes } from '@/Router/routes';
import { URL } from '../Configs/Urls';
import { fetchProfileAvatar } from './DevopsService';
import { apiRequest, apiRequestLoadingState } from './RequestsService';

export const authorize = () => {
    window.location.href = URL.auth.authorize;
};

export const signIn = async () => {
    const response = await apiRequestLoadingState(apiRequest, URL.auth.signIn, 'POST', { 'params': location.search });

    if (!response || !response.user) {
        fullReset();
    }

    user.userData = response.user;
    localStorage.setItem('drp-avatar', response.user.avatar);
    user.avatar = response.user.avatar;
    router.replace(routes.dashboard);

};

/**
 * Signs the user out from the app and from microsoft
 * - Clears all cookies, storage and user
 * - Logs user out of microsoft
 */
export const signOut = async () => {
    await apiRequestLoadingState(apiRequest, URL.auth.signOut)
        .then(() => {
            microsoftSignOutReset();
            window.location.href = URL.auth.logout;
        });
};

/**
 * Queries the server to checks if the user is signed in
 * 
 * @returns boolean
 */
export const signedInStatus = async () => {
    const response = await apiRequestLoadingState(apiRequest, URL.auth.signedInStatus);

    if (!response || !response.user) {
        resetUserStore();
        resetAppStore();
        return false;
    }

    user.userData = response.user;
    if (!user.avatar) {
        const avatar = await fetchProfileAvatar();
        if (avatar) {
            user.avatar = avatar;
            localStorage.setItem('drp-avatar', avatar);
        }
    }

    return true;
}

