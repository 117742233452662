export const messages = {
    success: {
        createSession: 'Refinement session successfully created',
        saveWorkItem: 'Work item saved',
        saveOrganization: 'Organisation saved successfully',
        saveProject: 'Project saved successfully',
        deleteSession: 'Refinement session successfully deleted',
        submitEstimate: 'Effort submitted'
    },
    alert: {
        createSession: 'Could not create the session. Ensure you have an organisation and project selected. Would you like to do it now?',
        getSessions: 'Could not find refinement sessions. Ensure you have an organisation and project selected. Would you like to do it now?',
        deleteSession: 'Are you sure you want to delete this refinement session? This action cannot be undone'
    },
    error: {
        fetchQuery: 'Failed to fetch queries. Ensure you have a organisation and project selected',
        fetchWorkItems: 'Could not fetch work items. Ensure your settings are correct',
        saveWorkItem: 'Something went wrong. Could not save the work item',
        submitCustomEstimate: 'Custom value must not be empty',
        fileType: 'File type not supported',
        uploadImage: 'Image upload failed',
        saveOrganization: 'Failed to save organisation',
        saveProject: 'Failed to save project',
        saveTeam: 'Failed to save team',
        saveIteration: 'Failed to save iteration',
        profileSettings: 'Could not load profile settings',
        getOrganizations: 'Could not load organisations',
        getProjects: 'Could not load projects',
        deleteSession: 'Failed to delete the refinement session',
        deleteSessionNotOwner: 'This refinement session is restricted. It can only be deleted by the owner',
        submitEstimate: 'Estimate submission failed',
        getTeams: 'Failed to fetch teams. Confirm you have an organisation and project selected',
        getIterations: 'Failed to fetch iterations. Confirm you have an organisation, project and a team selected'
    },
    info: {
        cookiesBanner: 'DevOps Refinement Portal uses strictly necessary cookies to handle authentication and user session actions required to sign in and navigate the portal.',
        imageUploadInfo: 'Current devops API limitations prevent images with a large width from saving (copy/pasted or uploaded from pc). For these cases, use URL upload or the official work item'
    }
};