import { DashboardState } from '@/Interfaces/Dashboard';
import { reactive } from 'vue';

// Create a new store instance.
const initialState: DashboardState = {
    team: '',
    teamsList: [],
    iteration: { id: '', name: '' },
    iterationsList: [],
};

export const dashboardStore: any = reactive({ ...initialState });

export const resetDashboardStore = () => {
    Object.assign(dashboardStore, initialState);
};