export const TypeLabelColorMap: Record<string, { background: string; border: string; }> = {
    'Product Backlog Item': { background: '#009CCC', border: '#009CCC' },
    'Task': { background: '#F2CB1D', border: '#F2CB1D' },
    'Bug': { background: '#CC293D', border: '#CC293D' },
    'Epic': { background: '#FF7B00', border: '#FF7B00' },
    'Feature': { background: '#773B93', border: '#773B93' },
    'Impediment': { background: '#B4009E', border: '#B4009E' },
    'Hotfix': { background: '#e60017', border: '#e60017' },
    'Security Item': { background: '#b20b1e', border: '#b20b1e' },
    'Test Case': { background: '#004B50', border: '#004B50' },
    'Test Plan': { background: '#004B50', border: '#004B50' },
    'Test Suite': { background: '#004B50', border: '#004B50' },
    'Regression Task': { background: '#e60017', border: '#e60017' },
    'PFI Bug': { background: '#e60017', border: '#e60017' },
    'Risk': { background: '#e60017', border: '#e60017' },
    'Dev Ideas': { background: '#ec008c', border: '#ec008c' },
    'Automation Test Task': { background: '#339947', border: '#339947' },
    'Automation Test Item': { background: '#339947', border: '#339947' },
};

export const StateLabelColorMap: Record<string, { background: string; border: string; }> = {
    'In Planning': { background: '#007acc', border: '#007acc' },
    'On Hold': { background: '#e60017', border: '#e60017' },
    'Inactive': { background: '#339933', border: '#339933' },
    'Active': { background: '#007acc', border: '#007acc' },
    'Ready': { background: '#007acc', border: '#007acc' },
    'Design': { background: '#b2b2b2', border: '#b2b2b2' },
    'Closed': { background: '#339933', border: '#339933' },
    'Open': { background: '#007acc', border: '#007acc' },
    'In Progress': { background: '#007acc', border: '#007acc' },
    'To Do': { background: '#b2b2b2', border: '#b2b2b2' },
    'Removed': { background: '#e60017', border: '#e60017' },
    'New': { background: '#b2b2b2', border: '#b2b2b2' },
    'Approved': { background: '#b2b2b2', border: '#b2b2b2' },
    'Committed': { background: '#007acc', border: '#007acc' },
    'Development': { background: '#aa9cdf', border: '#aa9cdf' },
    'Testing': { background: '#fbd144', border: '#fbd144' },
    'Ready for Testing': { background: '#9cc3b2', border: '#9cc3b2' },
    'Ready for Review': { background: '#cccccc', border: '#cccccc' },
    'Changes to Review': { background: '#D5D5D5', border: '#D5D5D5' },
    'Failed': { background: '#e60017', border: '#e60017' },
    'Done': { background: '#339933', border: '#339933' },
    'Completed': { background: '#339933', border: '#339933' },
};

export const randomColor = () => `rgba(${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)})`;