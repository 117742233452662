import { resetAppStore } from '@/Data/Store/appState';
import { resetUserStore } from '../Data/Store/user';
import router from '../Router/router';
import { iconsMap } from './Assets';
import { routes } from '@/Router/routes';
import { ConfirmDialog } from '@/Components/Dialogs/ConfirmDialog';
import { messages } from './Messages';

//adds a class to the navbar when it reaches a certain scroll position
export const navbarScrollAction = (size: number) => {
    const navBar = document.querySelector('nav');
    window.addEventListener('scroll', () => {
        if (navBar) {
            if (window.scrollY >= size) {
                navBar.classList.add('active-nav');
            } else {
                navBar.classList.remove('active-nav');
            }
        }
    });
};

/**
 * Gives or removes an element the active class
 * 
 * @param el the element
 * @param enable boolean
 */
export const setActiveInactiveSingleElement = (el: HTMLElement | null, enable: boolean) => {
    if (el) {
        enable ? el.classList.add('active-item') : el.classList.remove('active-item');
    }
};

/**
 * Gives or removes the active class to multiple elements by class name
 * 
 * @param className the element class name
 * @param enable boolean
 */
export const setActiveEnactiveMultipleItems = (className: string, enable: boolean) => {
    const item = document.getElementsByClassName(className);
    if (item) {
        for (let i = 0; i < item.length; i++) {
            enable ? item[i].classList.add('active-item') : item[i].classList.remove('active-item');
        }
    }
};

/**
 * Sets elements $el or $event.targets with active the class 
 * 
 * @param el the element
 * @param enable boolean
 */
export const setOneActiveAndOthersInactive = (el: any, className: string, enable: boolean) => {
    const item = document.getElementsByClassName(className);
    if (item) {
        for (let i = 0; i < item.length; i++) {
            item[i].classList.remove('active-item');
        }
    }
    if (enable && el) {
        el.classList.add('active-item');
    }
};

/**
 * Gives an element by index the active class and removes its from all others.
 * 
 * @param index the element index
 * @param className the class name to look for 
 */
export const setSelectedItemActiveByIndex = (index: any, className: string) => {
    const items = document.getElementsByClassName(className);
    if (items) {
        for (let i = 0; i < items.length; i++) {
            i === index ? items[i].classList.add('active-item') : items[i].classList.remove('active-item');
        }
    }
};

//sets all elements with the className to disabled with no pointer events
export const setEnabledDisabled = (className: string, enable: boolean) => {
    const item = document.getElementsByClassName(className);
    if (item) {
        for (let i = 0; i < item.length; i++) {
            enable ? item[i].classList.remove('disabled-item') : item[i].classList.add('disabled-item');
        }
    }
};

//Signs user out.
//Redirects the user to the landing page.
//Resets all states
export const fullReset = () => {
    router.push(routes.landing);
    localStorage.removeItem('avatar');
    resetUserStore();
    resetAppStore();
};

//Signs out functions but redirects the user to microsoft logout after
export const microsoftSignOutReset = () => {
    localStorage.removeItem('avatar');
    resetUserStore();
    resetAppStore();
};

/**
 * Finds an icon by icon name. Used for work items icons.
 * 
 * @param iconName the icon name
 * @returns the icon
 */
export const getIconByType = (iconName: string): any => {
    return iconsMap[iconName] || undefined;
};

/**
 * Formats a user name to just show first name and first letter of the last name
 * 
 * @param name the name
 * @returns a formatted version of the name
 */
export const formatName = (name: string) => {
    if (name) {
        const nameParts = name.split(' ');
        if (nameParts.length >= 2) {
            const firstName = nameParts[0];
            const lastNameInitial = nameParts[nameParts.length - 1].charAt(0);
            return `${firstName} ${lastNameInitial}.`;
        } else {
            return name;
        }
    }
    return name;
};

/**
 * Resizes a base64 image
 * 
 * @param base64 the image data
 * @returns a resized version of it
 */
export const resizeImage = async (base64: any) => {
    const maxWidth = 1000;
    const maxHeight = 600;
    const quality = 0.85;

    return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = base64;
        img.onload = () => {
            const canvas = document.createElement('canvas');
            let width = img.width;
            let height = img.height;

            if (width > maxWidth || height > maxHeight) {
                const aspectRatio = width / height;
                if (width > height) {
                    width = maxWidth;
                    height = width / aspectRatio;
                } else {
                    height = maxHeight;
                    width = height * aspectRatio;
                }
            }

            canvas.width = width;
            canvas.height = height;

            const ctx = canvas.getContext('2d')!;
            ctx.drawImage(img, 0, 0, width, height);

            canvas.toBlob(blob => {
                if (blob) {
                    const reader = new FileReader();
                    reader.readAsDataURL(blob);
                    reader.onloadend = () => {
                        resolve(reader.result);
                    };
                }
            }, 'image/jpeg', quality);
        };
        img.onerror = error => {
            reject(error);
        };
    });
};

export const createCookieBanner = () => {
    ConfirmDialog(messages.info.cookiesBanner,
        undefined, undefined,
        () => {
            localStorage.setItem('drp-cookies-consent', 'true');
        }
    );
};

export const getNewsAndUpdates = async (latest: boolean) => {
    const updates: any = [];
    const files = require.context('@/Data/Json/News', false, /\.json$/);
    files.keys().forEach(key => {
        const update = files(key);
        updates.push(update);
    });
    return latest ? updates[updates.length - 1] : updates;
};