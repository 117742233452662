import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "landing-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FeaturesCards = _resolveComponent("FeaturesCards")!
  const _component_FooterBar = _resolveComponent("FooterBar")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _cache[0] || (_cache[0] = _createElementVNode("div", { class: "welcome-container" }, [
        _createElementVNode("h1", null, "Welcome to DevOps Refinement Portal"),
        _createElementVNode("p", null, "A project crafted to assist organisations in optimising their DevOps workflows. This platform offers a suite of tools to refine and plan your work efficiently, enabling you to estimate, edit, and manage sprint items seamlessly within Azure DevOps. Dive into a toolset tailored to enhance your sprint planning experience and track refinement statistics effortlessly. Empower your organization with streamlined DevOps processes, all from one centralised portal.")
      ], -1)),
      _createVNode(_component_FeaturesCards)
    ]),
    _createVNode(_component_FooterBar)
  ], 64))
}