import { URL } from "../Configs/Urls";
import { apiRequest, apiRequestLoadingState } from "./RequestsService";

export const getRefinementWorkItems = async (sessionId: any) => {
    return await apiRequestLoadingState(apiRequest, URL.workItems.get, 'POST', { refinementId: sessionId });
};

export const getWorkItemsByIds = async (ids: any) => {
    return await apiRequest(URL.workItems.getById, 'POST', { workItemsIds: [ids] });
};

export const updateWorkItem = async (workItemUpdateDTO: any) => {
    return await apiRequest(URL.workItems.update, 'POST', { update: workItemUpdateDTO });
};