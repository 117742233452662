import { statusCode } from "@/Utils/StatusCodes";
import { appState } from "../Store/appState";

export const apiRequest = async (
    url: string,
    method: 'GET' | 'POST' | 'PUT' | 'DELETE' = 'GET',
    body: any = null,
    headers: Record<string, string> = { 'Accept': 'application/json' },
    expectJson: boolean = true
): Promise<any> => {
    try {
        const response = await fetch(url, {
            method,
            credentials: 'include',
            headers: {
                ...headers,
                ...(body ? { 'Content-Type': 'application/json' } : {}),
            },
            ...(body ? { body: JSON.stringify(body) } : {}),
        });

        if (response.status === statusCode.OK) {
            if (expectJson) {
                return await response.json();
            }
            return response.status;
        }

        console.error(`Request failed: ${response.status} ${response.statusText}`);
        return null;
    } catch (error) {
        console.error(`Request error: ${error}`);
        return null;
    }
};

export const apiRequestLoadingState = async (apiCall: Function, ...args: any[]) => {
    appState.loading = true;
    try {
        const response = await apiCall(...args);
        return response;
    } finally {
        appState.loading = false;
    }
};