<template>
  <nav class="navbar navbar-expand-lg navbar-dark fixed-top">
    <div class="container-fluid">
      <router-link class="navbar-brand" to="/">
        <img :src="icons.devops.logo" alt="Logo" style="margin-right: 10px;" width="30" height="30" />
        <div class="drp-title">DevOps Refinement Portal</div>
      </router-link>
      <button class="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#navbarOffcanvas"
        aria-controls="navbarOffcanvas" aria-expanded="false" aria-label="Toggle navigation">
        <img :src="icons.general.menu" alt="Off canvas header button" width="30" height="30" loading="lazy">
      </button>
      <div class="offcanvas offcanvas-end" tabindex="-1" id="navbarOffcanvas" aria-labelledby="navbarOffcanvasLabel">
        <div class="offcanvas-header">
          <img class="closeBtn" :src="icons.general.close" data-bs-dismiss="offcanvas" aria-label="Close" width="30"
            height="30" loading="lazy" />
        </div>
        <div class="offcanvas-body">
          <ul class="navbar-nav ms-auto" v-if="user.userData?.id && user.userData?.name">
            <li class="nav-item">
              <router-link class="nav-link inline-link" to="/dashboard">Dashboard</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link inline-link" to="/refinement">Refinement</router-link>
            </li>
            <li class="nav-item dropdown">
              <div class="nav-link inline-link" role="button" id="userInfoDropdown" data-bs-toggle="dropdown"
                aria-expanded="false">
                <img :src="theme === 'dark' ? icons.general.informationWhite : icons.general.informationBlack"
                  alt="information Icon" width="25" height="25" loading="lazy" />
              </div>
              <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="userInfoDropdown">
                <li>
                  <router-link class="dropdown-item dropdown-icon" to="/news">
                    <img :src="theme === 'dark' ? icons.general.newsWhite : icons.general.newsBlack" alt="Policy Icon"
                      width="20" height="20" loading="lazy" />
                    <span>News</span>
                  </router-link>
                </li>
                <li>
                  <router-link class="dropdown-item dropdown-icon" to="/privacy">
                    <img :src="theme === 'dark' ? icons.general.policyWhite : icons.general.policyBlack"
                      alt="Policy Icon" width="20" height="20" loading="lazy" />
                    <span>Privacy Policy</span>
                  </router-link>
                </li>
                <li>
                  <router-link class="dropdown-item dropdown-icon" to="/support">
                    <img :src="theme === 'dark' ? icons.general.supportWhite : icons.general.supportBlack"
                      alt="Support Icon" width="20" height="20" loading="lazy" />
                    <span>Support</span>
                  </router-link>
                </li>
              </ul>
            </li>
            <li class="nav-item dropdown">
              <div class="nav-link inline-link" role="button" id="userSettingsDropdown" data-bs-toggle="dropdown"
                aria-expanded="false" :title="userName">
                <img class="avatar-img"
                  :src="user.avatar ? `data:image/png;base64,${user.avatar}` : icons.general.defaultUserAvatar"
                  alt="Profile" width="30" height="30" loading="lazy" />
              </div>
              <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="userSettingsDropdown">
                <li>
                  <router-link class="dropdown-item dropdown-icon" to="/profile">
                    <img :src="icons.general.settings" alt="Settings Icon" width="20" height="20" loading="lazy" />
                    <span>Profile</span>
                  </router-link>
                </li>
                <li>
                  <div class="dropdown-item dropdown-icon" @click="$emit('themeToggle')">
                    <img :src="theme === 'dark' ? icons.theme.sun : icons.theme.moon" alt="Theme Icon" width="20"
                      height="20" loading="lazy" />
                    <span>{{ theme === 'dark' ? 'Light' : 'Dark' }}</span>
                  </div>
                </li>
                <li>
                  <hr class="dropdown-divider">
                </li>
                <li>
                  <div class="dropdown-item dropdown-icon" @click="signOut()">
                    <img :src="theme === 'dark' ? icons.general.leaveWhite : icons.general.leaveBlack" alt="Theme Icon"
                      width="20" height="20" loading="lazy" />
                    <span>Sign Out</span>
                  </div>
                </li>
              </ul>
            </li>
          </ul>
          <ul v-else class="navbar-nav ms-auto">
            <li v-if="!appState.loading" class="nav-item">
              <GenericButton @click="authorize()" text="Sign In" />
            </li>
          </ul>
        </div>
      </div>
    </div>
  </nav>
</template>

<script lang="tsx">
import { defineComponent } from 'vue';
import { authorize, signOut } from '@/Data/Services/AuthService';
import { user } from '@/Data/Store/user';
import { appState } from '@/Data/Store/appState';
import GenericButton from '../Buttons/GenericButton.vue';
import { icons } from '@/Utils/Assets';

export default defineComponent({
  props: {
    theme: String
  },
  components: {
    GenericButton
  },
  watch: {
    'user.userData.name': {
      handler (newName) {
        this.userName = newName;
      },
      immediate: true
    }
  },
  data () {
    return {
      icons,
      user,
      appState,
      authorize,
      signOut,
      userName: ''
    };
  },
})

</script>

<style lang="scss" scoped>
.closeBtn {
  cursor: pointer;
  right: 0;
  position: absolute;
  margin: 5px 10px 0 0;
}

.offcanvas-body {
  padding-top: 0;
}

.closeBtn:hover {
  opacity: 0.7;
}

nav {
  -webkit-box-shadow: 0 5px 7px -5px $primary-purple;
  -moz-box-shadow: 0 5px 7px -5px $primary-purple;
  box-shadow: 0 5px 7px -5px $primary-purple;
}

nav {
  background: $primary-dark;
  transition: background 0.5s ease-in-out;
}

nav .navbar-brand,
nav .navbar-nav .nav-link,
nav .dropdown-item,
nav .user-name {
  color: $white;
}

.light nav {
  background: $primary-light;
}

.light nav .navbar-brand,
.light nav .navbar-nav .nav-link,
.light nav .dropdown-item,
.light nav .user-name {
  color: $black;
}

nav .dropdown-menu {
  background-color: $primary-light;
  color: $primary-dark;
}

.dark nav {
  background: $primary-dark;
}

.dark nav .navbar-brand,
.dark nav .navbar-nav .nav-link,
.dark nav .dropdown-item,
.dark nav .user-name {
  color: $white;
}

.drp-title {
  font-size: large;
}

.navbar-brand {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.nav-link {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.navbar-nav {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.inline-link {
  border-radius: 5px;
}

.dark .inline-link:hover {
  background: $secondary-dark;
}

.light .inline-link:hover {
  background: $third-light;
}

.dark nav .dropdown-menu {
  background-color: $secondary-dark;
  color: $primary-light;
}

.light nav .dropdown-menu {
  background-color: $third-light;
  color: $primary-light;
}

nav .dropdown-item:hover,
nav .dropdown-item:focus {
  background: $primary-purple;
  color: $white;
}

.custom-btn-close {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0 10px 0 0;
  position: absolute;
  top: 0;
  right: 0;
}

.custom-btn-close span {
  font-size: 2rem;
  color: $secondary-purple;
}

.custom-btn-close:hover span {
  color: $primary-purple;
}

.dark .offcanvas {
  background-color: $primary-dark;
}

.light .offcanvas {
  background-color: $primary-light;
}

.dropdown-item {
  cursor: pointer;
}

.avatar-img {
  border-radius: 50%;
}

.dropdown-icon {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.navbar-toggler,
.navbar-toggler:focus {
  border: none;
}

.user-name {
  text-align: center;
}

.dark .dropdown-divider {
  border-top: 1px solid #666666;
}

@media (max-width: 990px) {
  .navbar-nav {
    flex-direction: column;
    align-items: flex-start;
  }

  .nav-link {
    padding: 0.5em;
  }
}
</style>
