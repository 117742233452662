import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, vModelSelect as _vModelSelect, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelCheckbox as _vModelCheckbox } from "vue"

const _hoisted_1 = { class: "modal-container" }
const _hoisted_2 = { class: "button-container" }
const _hoisted_3 = {
  type: "button",
  class: "btn",
  "data-bs-toggle": "modal",
  "data-bs-target": "#newBoardModal"
}
const _hoisted_4 = { class: "sessionButtonContent" }
const _hoisted_5 = ["src"]
const _hoisted_6 = {
  class: "modal fade",
  id: "newBoardModal",
  tabindex: "-1",
  "aria-hidden": "true"
}
const _hoisted_7 = { class: "modal-dialog modal-dialog-centered" }
const _hoisted_8 = { class: "modal-content" }
const _hoisted_9 = { class: "modal-body" }
const _hoisted_10 = { class: "mb-3" }
const _hoisted_11 = { class: "flex-container" }
const _hoisted_12 = { class: "left-dropdown" }
const _hoisted_13 = { class: "right-dropdown" }
const _hoisted_14 = { key: 0 }
const _hoisted_15 = {
  key: 1,
  class: "no-queries-container"
}
const _hoisted_16 = {
  key: 2,
  class: "spinner-container"
}
const _hoisted_17 = { class: "mb-3 flex-container-row" }
const _hoisted_18 = { class: "modal-footer" }
const _hoisted_19 = {
  key: 1,
  type: "button",
  class: "btn btn-disabled"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TreeNode = _resolveComponent("TreeNode")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("button", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("img", {
            src: _ctx.plusSign,
            width: "30",
            height: "30",
            loading: "lazy"
          }, null, 8, _hoisted_5),
          _cache[6] || (_cache[6] = _createElementVNode("span", null, "New Session", -1))
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, [
          _cache[11] || (_cache[11] = _createElementVNode("div", { class: "modal-header" }, [
            _createElementVNode("h5", {
              class: "modal-title",
              id: "newBoardModalLabel"
            }, "New Session")
          ], -1)),
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("div", _hoisted_10, [
              _cache[7] || (_cache[7] = _createElementVNode("label", {
                for: "titleForm",
                class: "form-label"
              }, "Title", -1)),
              _withDirectives(_createElementVNode("input", {
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.data.title) = $event)),
                type: "text",
                class: "form-control",
                id: "titleForm",
                placeholder: "Session name..."
              }, null, 512), [
                [
                  _vModelText,
                  _ctx.data.title,
                  void 0,
                  { trim: true }
                ]
              ])
            ]),
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("div", _hoisted_12, [
                _withDirectives(_createElementVNode("select", {
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.data.type) = $event)),
                  onChange: _cache[2] || (_cache[2] = (event) => { _ctx.handleTypeSelection(event.target.value); }),
                  class: "form-select",
                  "aria-label": ".form-select-md"
                }, _cache[8] || (_cache[8] = [
                  _createElementVNode("option", { value: undefined }, "Refinement Type...", -1),
                  _createElementVNode("option", { value: "Query" }, "Query", -1)
                ]), 544), [
                  [_vModelSelect, _ctx.data.type]
                ])
              ]),
              _createElementVNode("div", _hoisted_13, [
                (_ctx.data.type === 'Query' && _ctx.queriesList.length > 0 && !_ctx.loadingQueries)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                      _createVNode(_component_TreeNode, {
                        treeData: _ctx.queriesList,
                        onItemSelected: _ctx.setQueryData
                      }, null, 8, ["treeData", "onItemSelected"])
                    ]))
                  : (!_ctx.loadingQueries && _ctx.queriesList.length <= 0 && _ctx.data.type === 'Query')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_15, "No queries found"))
                    : _createCommentVNode("", true),
                (_ctx.loadingQueries)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_16, _cache[9] || (_cache[9] = [
                      _createElementVNode("div", { class: "spinner d-flex justify-content-center align-items-center" }, [
                        _createElementVNode("div", {
                          class: "spinner-border",
                          role: "status"
                        }, [
                          _createElementVNode("span", { class: "visually-hidden" }, "Loading...")
                        ])
                      ], -1)
                    ])))
                  : _createCommentVNode("", true)
              ])
            ]),
            _createElementVNode("div", _hoisted_17, [
              _cache[10] || (_cache[10] = _createElementVNode("label", {
                class: "form-check-label",
                for: "restricted"
              }, "Restrict session control to owner", -1)),
              _withDirectives(_createElementVNode("input", {
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.data.restricted) = $event)),
                class: "form-check-input",
                type: "checkbox",
                id: "restricted"
              }, null, 512), [
                [_vModelCheckbox, _ctx.data.restricted]
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_18, [
            _createElementVNode("button", {
              type: "button",
              class: "btn",
              "data-bs-dismiss": "modal",
              onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.modalReset()))
            }, "Cancel"),
            (_ctx.data.title.length > 0 && _ctx.data.type)
              ? (_openBlock(), _createElementBlock("button", {
                  key: 0,
                  type: "button",
                  class: "btn",
                  "data-bs-dismiss": "modal",
                  onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.saveModal()))
                }, "Save"))
              : (_openBlock(), _createElementBlock("button", _hoisted_19, "Save"))
          ])
        ])
      ])
    ])
  ]))
}