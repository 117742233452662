import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "settings-container" }
const _hoisted_2 = { class: "fields-list-container" }
const _hoisted_3 = { class: "field-container" }
const _hoisted_4 = { class: "select-row" }
const _hoisted_5 = ["value"]
const _hoisted_6 = {
  key: 0,
  class: "spinner d-flex justify-content-center align-items-center"
}
const _hoisted_7 = ["src"]
const _hoisted_8 = {
  key: 0,
  class: "field-container"
}
const _hoisted_9 = { class: "select-row" }
const _hoisted_10 = ["value"]
const _hoisted_11 = {
  key: 0,
  class: "spinner d-flex justify-content-center align-items-center"
}
const _hoisted_12 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[12] || (_cache[12] = _createElementVNode("h4", { style: {"margin-bottom":"1em"} }, "Profile Settings", -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[8] || (_cache[8] = _createElementVNode("label", {
          for: "organizationMenu",
          class: "form-label"
        }, "Organisation", -1)),
        _createElementVNode("div", _hoisted_4, [
          _withDirectives(_createElementVNode("select", {
            id: "organizationMenu",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedOrganization) = $event)),
            onChange: _cache[1] || (_cache[1] = (event) => { _ctx.selectOrganization(event.target.value); }),
            class: "select-drop",
            "aria-label": "Organization"
          }, [
            _cache[6] || (_cache[6] = _createElementVNode("option", { value: '' }, "Choose organisation...", -1)),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.organizationsList, (organization) => {
              return (_openBlock(), _createElementBlock("option", {
                key: organization,
                value: organization
              }, _toDisplayString(organization), 9, _hoisted_5))
            }), 128))
          ], 544), [
            [_vModelSelect, _ctx.selectedOrganization]
          ]),
          (_ctx.loadingOrg)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, _cache[7] || (_cache[7] = [
                _createElementVNode("div", {
                  class: "spinner-border",
                  role: "status"
                }, null, -1)
              ])))
            : (_openBlock(), _createElementBlock("div", {
                key: 1,
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.getOrganizations()))
              }, [
                _createElementVNode("img", {
                  class: "icon-refresh",
                  width: "30",
                  height: "30",
                  src: _ctx.theme === 'dark' ? _ctx.icons.general.reloadWhite : _ctx.icons.general.reloadBlack,
                  loading: "lazy"
                }, null, 8, _hoisted_7)
              ]))
        ])
      ]),
      (_ctx.projectsEnabled)
        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
            _cache[11] || (_cache[11] = _createElementVNode("label", {
              for: "projectsMenu",
              class: "form-label"
            }, "Project", -1)),
            _createElementVNode("div", _hoisted_9, [
              _withDirectives(_createElementVNode("select", {
                id: "projectsMenu",
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.selectedProject) = $event)),
                onChange: _cache[4] || (_cache[4] = (event) => { _ctx.selectProject(event.target.value); }),
                class: "select-drop",
                "aria-label": "Project"
              }, [
                _cache[9] || (_cache[9] = _createElementVNode("option", { value: '' }, "Choose project...", -1)),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.projectsList, (project) => {
                  return (_openBlock(), _createElementBlock("option", {
                    key: project,
                    value: project
                  }, _toDisplayString(project), 9, _hoisted_10))
                }), 128))
              ], 544), [
                [_vModelSelect, _ctx.selectedProject]
              ]),
              (_ctx.loadingProj)
                ? (_openBlock(), _createElementBlock("div", _hoisted_11, _cache[10] || (_cache[10] = [
                    _createElementVNode("div", {
                      class: "spinner-border",
                      role: "status"
                    }, null, -1)
                  ])))
                : (_openBlock(), _createElementBlock("div", {
                    key: 1,
                    onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.getProjects()))
                  }, [
                    _createElementVNode("img", {
                      class: "icon-refresh",
                      width: "30",
                      height: "30",
                      src: _ctx.theme === 'dark' ? _ctx.icons.general.reloadWhite : _ctx.icons.general.reloadBlack,
                      loading: "lazy"
                    }, null, 8, _hoisted_12)
                  ]))
            ])
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}