import { URL } from "../Configs/Urls";
import { apiRequest } from "./RequestsService";

export const fetchProfileSettings = async () => {
    return await apiRequest(URL.profile.get);
};

export const fetchProfileAvatar = async () => {
    return await apiRequest(URL.profile.avatar);
};

export const fetchTeams = async () => {
    return await apiRequest(URL.teams.get);
};

export const saveTeam = async (team: string) => {
    return await apiRequest(URL.teams.dashboard.save, 'POST', { team }, {}, false);
};

export const fetchIterations = async (team: string) => {
    return await apiRequest(URL.iterations.get, 'POST', { team });
};

export const saveIteration = async (iteration: Object) => {
    return await apiRequest(URL.iterations.dashboard.save, 'POST', { iteration }, {}, false);
};

export const fetchOrganizations = async () => {
    return await apiRequest(URL.organizations.get);
};

export const saveOrganization = async (org: string) => {
    return await apiRequest(URL.organizations.save, 'POST', { organization: org }, {}, false);
};

export const fetchProjects = async () => {
    return await apiRequest(URL.projects.get);
};

export const saveProject = async (proj: string) => {
    return await apiRequest(URL.projects.save, 'POST', { project: proj }, {}, false);
};

export const fetchDashboardSettings = async () => {
    return await apiRequest(URL.dashboard.getSettings);
};

export const fetchDashboardWorkItemData = async (data: any) => {
    return await apiRequest(URL.dashboard.getWorkItemData, 'POST', data);
};

export const fetchDashboardSprintData = async (data: any) => {
    return await apiRequest(URL.dashboard.getSprintData, 'POST', data);
};