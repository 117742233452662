<template>
    <div :id="item?.id" :class="['work-item', { 'disabled': disabled }]">
        <div class="work-item-content">
            <div class="left-item-content" v-on:click="selectWorkItem">
                <div class="bottom-content">
                    <div class="item-titte-container">
                        <img :src="itemIcon" width="15" height="15" loading="lazy" />
                        <span>{{ item?.id }}</span>
                    </div>
                    <div>Effort: {{ item?.effort }}</div>
                </div>
                <div>{{ item?.title }}</div>
            </div>
        </div>
    </div>
</template>
<script lang="tsx">
import { defineComponent } from 'vue';
import { getIconByType } from '@/Utils/HelperFunctions';
import { icons } from '@/Utils/Assets';

export default defineComponent({
    props: {
        theme: String,
        item: Object as any,
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            itemIcon: undefined,
            icons,

        };
    },
    async beforeMount () {
        if (this.item?.type) {
            this.itemIcon = await getIconByType(this.item?.type);
        }
    },
    methods: {
        selectWorkItem () {
            this.$emit('itemSelect');
        }
    }
});
</script>

<style lang="scss" scoped>
.work-item:focus {
    background: $primary-purple !important;
}

.light .work-item {
    background: $third-dark;
    color: $black;
}

.dark .work-item {
    background: $secondary-dark;
    color: $white;
}

.work-item {
    color: $white;
    border-radius: 10px;
    margin-bottom: 0.5em;
    cursor: pointer;
    border-left: 0.2em solid $primary-purple;
}

.work-item:not(.disabled):hover {
    background: $primary-purple;
    color: $white;
}

.work-item-content {
    display: flex;
    flex-direction: row;
}

.left-item-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    word-wrap: break-word;
    gap: 1em;
    padding: 0.5em 1em 0.5em 1em;
}

.right-item-content {
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    padding: 0.5em 1em 0 0;
}

.bottom-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.active-item {
    color: $white !important;
    background: $primary-purple !important;
}

.item-titte-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
}

.disabled {
    cursor: auto;

}
</style>
