<template>
    <Doughnut :data="chartData" :options="chartOptions" />
</template>

<script lang="tsx">
import { defineComponent } from 'vue';
import { Doughnut } from 'vue-chartjs';
import { Chart as ChartJS, Title, Tooltip, Legend, ArcElement, CategoryScale } from 'chart.js';
import { TypeLabelColorMap } from '@/Utils/ColourMapping';

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale);

export default defineComponent({
    name: 'DoughnutChart',
    components: { Doughnut },
    props: {
        data: {
            type: Object as () => Record<string, number>,
            default: () => ({})
        },
        theme: String,
        title: String
    },
    computed: {
        chartData () {
            const labels = Object.keys(this.data);
            const backgroundColors = labels.map(label => TypeLabelColorMap[label]?.background || 'rgba(200, 200, 200, 0.2)');

            return {
                labels: labels,
                datasets: [
                    {
                        data: Object.values(this.data),
                        backgroundColor: backgroundColors,
                        borderWidth: 0.5
                    }
                ]
            };
        },
        chartOptions () {
            return {
                responsive: true,
                maintainAspectRatio: true,
                aspectRatio: 1.5,
                plugins: {
                    title: {
                        display: true,
                        text: this.title,
                        font: {
                            size: 20
                        },
                        color: this.theme === 'dark' ? 'white' : 'black'
                    },
                    legend: {
                        labels: {
                            color: this.theme === 'dark' ? 'white' : 'black'
                        }
                    },
                    datalabels: {
                        formatter: (value: number, context: any) => {
                            const dataset = context.chart.data.datasets[0].data;
                            const total = dataset.reduce((sum: number, val: number) => sum + val, 0);
                            const percentage = ((value / total) * 100).toFixed(2);
                            return `${value} (${percentage}%)`;
                        },
                        color: this.theme === 'dark' ? 'white' : 'black',
                        font: {
                            size: 20,
                            weight: 'bold' as 'bold' | 'normal' | 'bolder' | 'lighter'
                        }
                    }
                }
            };
        }
    }
});
</script>