import { URL } from "../Configs/Urls";
import { apiRequest, apiRequestLoadingState } from "./RequestsService";

export const getRefinementSessions = async () => {
    const response = await apiRequestLoadingState(apiRequest, URL.sessions.get);
    if (response) {
        return response.refinementSessions;
    }
    return response;
};

export const saveRefinementSession = async (data: any) => {
    return await apiRequest(URL.sessions.save, 'POST', data, {}, false);
};

export const deleteSession = async (sessionId: string) => {
    return await apiRequest(URL.sessions.delete, 'POST', { sessionId: sessionId }, {}, false);
};

export const getRefinmentQueries = async () => {
    const response = await apiRequest(URL.sessions.getQueries);
    if (response && response.value.length > 0) {
        return response.value;
    }
    return response;
};
